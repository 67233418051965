import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Progress, Button } from 'antd'
import ProFeaturesBanner from './components/ProFeaturesBanner'
import getRandomInt from '@/utils/getRandomInt'
import getRandomQuote from '@/utils/getRandomQuote'
import { restartDeviceSession } from '@/store/labs/actions'
import { Content } from './styles'
import Quote from '@/assets/images/quote.svg'

const LabLoading = ({ title, subtitle, loadTime = 10000 }) => {
  const dispatch = useDispatch()

  const { userProfile } = useSelector((state) => state.users)
  const isPro = userProfile?.groups?.includes('Pro')

  const { currentLab: lab } = useSelector((state) => state.labs)
  const labSession = lab?.allocated_session
  const deviceSessions = labSession?.devices
  const someDeviceHasError = deviceSessions?.some((d) => d.status === 'error')

  const [deviceLoadTimes, setDeviceLoadTimes] = useState({})
  const [randomQuote, setRandomQuote] = useState({})

  const _STEP = 1000
  let timer = setTimeout(function () {
    if (!Object.keys(deviceLoadTimes).length) {
      return
    }

    let auxDeviceLoadTimes = { ...deviceLoadTimes }

    for (const deviceId in auxDeviceLoadTimes) {
      const { timeLeft } = auxDeviceLoadTimes[deviceId]

      if (timeLeft > 0) {
        auxDeviceLoadTimes[deviceId].timeLeft -= _STEP
      }
    }

    setDeviceLoadTimes(auxDeviceLoadTimes)
  }, _STEP)

  useEffect(() => {
    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceLoadTimes])

  const handleRestartDevice = async (deviceId) => {
    const auxDeviceLoadTimes = { ...deviceLoadTimes }

    if (auxDeviceLoadTimes[deviceId]) {
      auxDeviceLoadTimes[deviceId].timeLeft = auxDeviceLoadTimes[deviceId].totalTime
    }

    await dispatch(restartDeviceSession(labSession?.id, deviceId))
    setDeviceLoadTimes(auxDeviceLoadTimes)
  }

  useEffect(() => {
    setRandomQuote(getRandomQuote())

    let auxDeviceLoadTimes = {}
    deviceSessions?.forEach((ds) => {
      const randomTotalTime = loadTime + getRandomInt(1, 10) * 1000
      auxDeviceLoadTimes[ds.id] = {
        totalTime: randomTotalTime,
        timeLeft: randomTotalTime,
      }
    })

    setDeviceLoadTimes(auxDeviceLoadTimes)
  }, [labSession?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Content isPro={isPro}>
      <div className="info-container">
        <h3 className="title">{title}</h3>
        <p className="subtitle">{subtitle || 'In a few seconds you will be able to start working on it'}</p>

        <div className="device-loading-container">
          {deviceSessions?.map((ds, i) => {
            const isStarted = ds.status === 'started'
            const isStopped = ds.status === 'stopped'
            const isError = ds.status === 'error'

            const perc = parseInt((1 - deviceLoadTimes[ds.id]?.timeLeft / deviceLoadTimes[ds.id]?.totalTime) * 100)
            return (
              <div key={ds.id} className="device-loading-progress">
                <p className="device-name">
                  Device {i + 1}: <b>{ds.device.name}</b>
                </p>

                <div className="progress-container">
                  <Progress
                    percent={isError || isStopped ? 100 : isStarted ? 85 : perc}
                    status={isError || isStopped ? 'exception' : 'active'}
                    showInfo={false}
                  />

                  {(isError || isStopped) && (
                    <Button danger className="restart-btn" onClick={() => handleRestartDevice(ds.id)}>
                      {isError ? 'Retry' : 'Restart'}
                    </Button>
                  )}
                </div>

                {(ds.error || ds.status === 'error') && (
                  <span className="error-msg">{ds.error || 'There was an error with this lab device.'}</span>
                )}
                {isStopped && <span className="error-msg">Device is stopped</span>}
              </div>
            )
          })}
        </div>
      </div>

      {someDeviceHasError ? (
        <div className="error-box">
          {subtitle} If the problem persists, please send us an email to:{' '}
          <a href="mailto:support@datawars.io?subject=Error while starting device">support@datawars.io</a>.
        </div>
      ) : isPro ? (
        <div className="quote-container">
          <img src={Quote} alt="" />
          <h5>{randomQuote['quote']}</h5>
          <p>— {randomQuote['author']}</p>
        </div>
      ) : (
        <ProFeaturesBanner />
      )}
    </Content>
  )
}

export default LabLoading
