import { useSelector, useDispatch } from 'react-redux'
import { Tooltip } from 'antd'
import { selectPage, selectObjective } from '@/store/modules/actions'
import { Container, PagesContainer, Page } from './styles'

const Pagination = ({ groupedPages }) => {
  const dispatch = useDispatch()

  const { currentPage, currentObjective } = useSelector((state) => state.modules)

  return (
    <Container className="assessment-pagination">
      {Object.keys(groupedPages)?.map((objective) => {
        const hasPageWithContent = groupedPages?.[objective]?.some((page) => 'content_html' in page)
        const allPagesHaveAction = groupedPages?.[objective]?.every((page) => !!page?.actions?.length)

        return (
          <Tooltip key={objective} title={objective} placement="top">
            <PagesContainer
              className="pages-container"
              hasContent={hasPageWithContent}
              isCollapsed={objective !== currentObjective}
              isCompleted={allPagesHaveAction}
              onClick={
                hasPageWithContent && objective !== currentObjective
                  ? () => {
                      dispatch(selectObjective(objective))
                      dispatch(selectPage(0))
                    }
                  : null
              }
            >
              {groupedPages?.[objective]?.map((page, pageIndex) => {
                const hasContent = 'content_html' in page
                const hasActions = !!page?.actions?.length
                const isLastWithContent = groupedPages?.[objective]?.findLast((p) => 'content_html' in p) === page

                return (
                  <Page
                    key={page?.id}
                    className="page"
                    hasContent={hasContent}
                    isVisited={hasActions || isLastWithContent}
                    isActive={currentObjective === objective && currentPage === pageIndex}
                    onClick={hasContent ? () => dispatch(selectPage(pageIndex)) : null}
                  >
                    <div className="content">
                      <span className="value">{pageIndex + 1}</span>
                    </div>
                  </Page>
                )
              })}

              <span className="text">
                {groupedPages?.[objective]?.map((p) => 'content_html' in p)?.filter((p) => p)?.length}/
                {groupedPages?.[objective]?.length}
              </span>
            </PagesContainer>
          </Tooltip>
        )
      })}
    </Container>
  )
}

export default Pagination
