import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { Pagination } from 'antd'
import ModuleBox from '@/components/ModuleBox'
import { getMultiModulesProgress, setCatalogIsLoading } from '@/store/modules/actions'
import NoResults from '@/assets/images/no-results.svg'
import { Container } from './styles'

const Results = ({ results, indexUiState, status, refine, page, setPage }) => {
  const dispatch = useDispatch()

  const { pageSize: defaultPageSize } = useSelector((state) => state.app)
  const { multiModulesProgress, isCatalogLoading } = useSelector((state) => state.modules)

  const currentSearchParameters = indexUiState?.configure

  const isLoading = !results?.params || status === 'loading' || status === 'stalled'
  const searchText = indexUiState?.query

  const [pageSize, setPageSize] = useState(defaultPageSize)

  const getModuleEarlyAccess = (module) => {
    const isPublished = module?.status?.toLowerCase() === 'published'
    const isEarlyAccess = dayjs.unix(module?.general_available_at) > dayjs()

    return isPublished && isEarlyAccess
  }

  const getModuleScoring = (module) => {
    const moduleProgress = multiModulesProgress?.find((l) => l.module_id === module?.id) || {}

    return moduleProgress?.scoring || 0
  }

  const handleTableChange = (newPage, newPageSize) => {
    setPage(newPage)
    setPageSize(newPageSize)
  }

  useEffect(() => {
    refine({
      ...currentSearchParameters,
      page: page - 1,
      hitsPerPage: pageSize,
    })
  }, [page, pageSize])

  useEffect(() => {
    if (!results?.hits?.length) {
      if (results?.params) {
        dispatch(setCatalogIsLoading(false))
      }

      return
    }

    dispatch(
      getMultiModulesProgress(
        results?.hits?.map((module) => module?.objectID?.split('-')?.[0]),
        () => dispatch(setCatalogIsLoading(false)),
      ),
    )
  }, [results])

  return (
    <Container className="catalog-search-results">
      <div className="content">
        {(isLoading || isCatalogLoading) && (
          <div className="results-container">
            <div className="module-container">
              <ModuleBox isLoading border />
            </div>
            <div className="module-container">
              <ModuleBox isLoading border />
            </div>
            <div className="module-container">
              <ModuleBox isLoading border />
            </div>
            <div className="module-container">
              <ModuleBox isLoading border />
            </div>
            <div className="module-container">
              <ModuleBox isLoading border />
            </div>
            <div className="module-container">
              <ModuleBox isLoading border />
            </div>
            <div className="module-container">
              <ModuleBox isLoading border />
            </div>
          </div>
        )}

        {results?.hits?.length === 0 && !isLoading && !isCatalogLoading && (
          <div className="no-results-box">
            <img src={NoResults} alt="no-results" />
            <h4 className="title">No projects found</h4>
            <h5 className="text">
              {searchText && (
                <span>
                  “{searchText}” did not match any project.
                  <br />
                </span>
              )}
              Please try again.
            </h5>
          </div>
        )}

        <div className="results-container">
          {!isCatalogLoading &&
            results?.hits?.map((hit) => {
              if (!hit.id) {
                hit.id = hit.objectID
              }

              const module = {
                ...hit,
                tags: hit?.tags?.map((t) => t.name),
                early_access: getModuleEarlyAccess(hit),
                user_status: {
                  last_module_attempt: {
                    user_status: {
                      scoring: getModuleScoring(hit),
                    },
                  },
                },
              }

              return (
                <div key={module?.id} className="module-container">
                  <ModuleBox module={module} border showSkillTrack />
                </div>
              )
            })}

          {!!results?.hits?.length && !isCatalogLoading && (
            <div className="pagination-container">
              <Pagination
                className="pagination-box"
                onChange={handleTableChange}
                current={page}
                pageSize={pageSize}
                total={results?.nbHits}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} projects`}
              />
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}

export default Results
