import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import Button from '@/components/Button'
import ModuleTimer from '../ModuleTimer'
import { toggleUpgradeToProModal } from '@/store/app/actions'
import { extendModuleAttempt, setModuleTimerModalAutoOpened, setModuleTimerModalOpen } from '@/store/modules/actions'
import { setLastLabActionDate, setInactivityModalOpen } from '@/store/labs/actions'
import ProBadge from '@/assets/images/plans/pro-badge.png'
import { Modal } from './styles'

const ModuleTimerModal = () => {
  const dispatch = useDispatch()

  const { userProfile } = useSelector((state) => state.users)
  const { currentModule, isModuleAttemptLoading, isModuleTimerModalOpen } = useSelector((state) => state.modules)

  const canResumeLab = userProfile?.permissions?.includes('labs.resume_lab')

  const moduleAttempt = currentModule?.user_status?.last_module_attempt

  const requestMoreTime = () => {
    dispatch(
      extendModuleAttempt(currentModule?.id, moduleAttempt?.id, () => {
        dispatch(setModuleTimerModalAutoOpened(false))
        dispatch(setModuleTimerModalOpen(false))

        dispatch(setLastLabActionDate(dayjs().toISOString()))
        dispatch(setInactivityModalOpen(false))
      }),
    )
  }

  return (
    <Modal
      open={isModuleTimerModalOpen}
      onCancel={() => {
        dispatch(setModuleTimerModalOpen(false))
      }}
      destroyOnClose={true}
      footer={null}
      className="module-timer-modal"
    >
      <div className="content">
        <div className="timer-container">
          <ModuleTimer isDisabled isLarge />
        </div>

        <div className="header">
          <h5 className="text">You're running out of time...</h5>

          <h3 className="title">Need more time? It's on us.</h3>
        </div>

        {!canResumeLab && (
          <div className="pro-info">
            <p className="pro-simple-text">
              PRO users enjoy unlimited time to resolve projects, and the ability to resume them once stopped.
            </p>

            <Button type="primary" ispro="true" onClick={() => dispatch(toggleUpgradeToProModal(true))}>
              <img className="pro-badge" src={ProBadge} alt="Update to PRO" />
              Upgrade Now
            </Button>
          </div>
        )}

        <Button
          className="cta-button"
          size="large"
          type="primary"
          onClick={requestMoreTime}
          loading={isModuleAttemptLoading}
        >
          Add 5 extra minutes
        </Button>
      </div>
    </Modal>
  )
}

export default ModuleTimerModal
