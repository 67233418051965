import styled, { css } from 'styled-components'
import { Layout as AntLayout } from 'antd'
import { device } from '@/themes/breakpoints'

export const Layout = styled(AntLayout)`
  height: 100dvh;
  background-color: ${(props) => props.theme.colors.gray2};
  overflow-y: auto;

  .whitelabel-logo {
    width: 124px;
    position: absolute;
    bottom: 16px;
    right: 16px;
    opacity: 0.25;
    user-select: none;
    pointer-events: none;
    z-index: 0;

    @media ${device.lgDown} {
      width: 84px;
      bottom: 12px;
      right: 12px;
    }
  }
`

export const Content = styled(Layout.Content)`
  width: 100%;
  display: flex;

  .reflex-container {
    .left-pane,
    .right-pane,
    .children-pane {
      display: flex;
    }

    .middle-splitter {
      width: 6px;
      background-color: ${(props) => props.theme.colors.gray7};
      border: none;
      display: block;
    }

    ${(props) =>
      props.isfullscreen === 'true' &&
      css`
        .left-pane {
          flex: 0 !important;
        }

        .middle-splitter {
          display: none !important;
        }

        .right-pane {
          flex: 1 !important;
        }
      `}

    @media ${device.lgDown} {
      flex-direction: column-reverse;

      .reflex-container {
        flex-direction: column;
      }

      .left-pane,
      .right-pane {
        flex: initial !important;
      }

      .right-pane {
        height: initial;
      }

      .middle-splitter {
        display: none !important;
      }
    }
  }
`

export const MainContent = styled(Layout.Content)`
  width: 100%;
  flex: 1 1 20em;
`

export const SecondaryContent = styled(Layout.Content)`
  width: 100%;
  flex: 2 1 20em;
  overflow: auto;
`

export const ChildrenContent = styled(Layout.Content)`
  display: flex;
  align-items: flex-start;
  overflow: auto;
`
