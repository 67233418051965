import { InstantSearch, Configure, SearchBox, Breadcrumb } from 'react-instantsearch'
import { SearchOutlined } from '@ant-design/icons'
import { algoliaSearchClient } from '@/utils/algolia'
import SearchResults from './components/SearchResults'
import Filters from './components/Filters'
import TaskFilter from './components/TaskFilter'
import searchParameters from './searchParameters'
import { Container } from './styles'

const DataSourcesSearch = () => {
  return (
    <InstantSearch searchClient={algoliaSearchClient} indexName={import.meta.env.REACT_APP_ALGOLIA_DATASOURCES_INDEX}>
      <Configure {...searchParameters} />

      <Container className="datasources-search-container">
        <div className="main-content">
          <div className="search-box">
            <SearchOutlined />
            <SearchBox placeholder="Search dataset..." />
          </div>

          <div className="results">
            <Breadcrumb
              attributes={['tasks.lvl0', 'tasks.lvl1', 'tasks.lvl2']}
              translations={{
                rootElementText: 'All tasks',
              }}
            />

            <SearchResults />
          </div>
        </div>

        <div className="filters-container">
          <TaskFilter />
          <Filters />
        </div>
      </Container>
    </InstantSearch>
  )
}

export default DataSourcesSearch
