import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Rate, Spin, Skeleton } from 'antd'
import ModuleBox from '@/components/ModuleBox'
import ModuleRatingModal from './components/ModuleRatingModal'
import { createModuleRating, setRatingModalOpen } from '@/store/modules/actions'

import { Container } from './styles'

const ModuleExtraContent = () => {
  const dispatch = useDispatch()

  const { currentModule, relatedModulesInfo, isModuleRatingLoading } = useSelector((state) => state.modules)
  const moduleRating = currentModule?.user_status?.module_rating

  const [animationKey, setAnimationKey] = useState()
  const [ratingValue, setRatingValue] = useState(moduleRating?.rating)

  const isCustomProject = currentModule?.title?.name === 'Custom Projects'
  const shouldShowFloatingRating = currentModule?.user_status?.last_finished_at && !moduleRating?.rating

  const openExtraRating = () => {
    dispatch(setRatingModalOpen(true))
  }

  const handleRatingChange = async (rating) => {
    setRatingValue(rating)
    const res = await dispatch(createModuleRating(currentModule.id, { rating }))

    if (res) openExtraRating()
  }

  useEffect(() => {
    setAnimationKey(Math.random())
  }, [])

  useEffect(() => {
    if (!ratingValue && moduleRating?.rating) setRatingValue(moduleRating.rating)
  }, [moduleRating]) // eslint-disable-line react-hooks/exhaustive-deps

  const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful']

  return (
    <Container className="module-extra-content" extraBottomMargin={shouldShowFloatingRating}>
      <div key={animationKey} className="module-content">
        <hr />

        {currentModule && relatedModulesInfo?.related_modules ? (
          <>
            {!!relatedModulesInfo?.related_modules?.length && <h5>Continue with</h5>}

            <div className="modules-container">
              {relatedModulesInfo?.related_modules?.slice(0, 3)?.map((m) => {
                return <ModuleBox key={m?.id} module={m} skipUserStatus />
              })}
            </div>
          </>
        ) : (
          <div className="loading-container">
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          </div>
        )}

        <div
          className={`see-all-modules-container ${
            relatedModulesInfo?.related_modules?.length > 3 ? 'has-more-modules' : ''
          }`}
        >
          <p className="link">
            {!isCustomProject && (
              <>
                <Link to={`/skill-track/${currentModule?.skill_track?.id}`}>Back to Track page</Link> or{' '}
              </>
            )}
            <Link to="/catalog?status=Published">Explore all projects on the Catalog</Link>
          </p>
        </div>
      </div>

      <div className="floating-rating-content">
        <div className="floating-box">
          <h5 className="title">Did you like this project?</h5>

          {!moduleRating?.rating && <p className="text">Rate your experience</p>}

          {isModuleRatingLoading ? (
            <Spin spinning />
          ) : (
            <Rate
              className="rate"
              tooltips={!moduleRating?.rating && desc}
              value={ratingValue}
              onChange={handleRatingChange}
              disabled={isModuleRatingLoading || moduleRating?.rating}
            />
          )}

          {moduleRating?.rating &&
            (!moduleRating?.content_rating || !moduleRating?.activities_rating ? (
              <p className="link" onClick={openExtraRating}>
                <span>Submit more in-depth rating</span>
              </p>
            ) : (
              <p>Your feedback was submitted!</p>
            ))}
        </div>
      </div>

      <ModuleRatingModal />
    </Container>
  )
}

export default ModuleExtraContent
