import {
  SET_USERS_LOADING,
  SET_USER_STATS_LOADING,
  USER_AUTHENTICATION_UPDATED,
  USER_PROFILE_UPDATED,
  USER_STATS_UPDATED,
  USER_EMAIL_VALIDATION_UPDATED,
  USER_BILLING_URL_UPDATED,
  TOGGLE_AUTH_MODAL,
  TOGGLE_PUBLIC_PROFILE_MODAL,
  TOGGLE_EMAIL_VERIFICATION_MODAL,
  SET_USERS_ERROR,
  CLEAR_ERRORS,
} from '@/store/users'
import {
  postRegisterUser,
  postAuthUserWithGoogle,
  postAuthenticateUser,
  postLogoutUser,
  postForgotPassword,
  postResetPassword,
  fetchUserMe,
  fetchUserStats,
  postValidateUserEmail,
  postConfirmUserEmail,
  postUserCheckout,
  postUserBilling,
  postUserFeedback,
} from '@/services/users'
import { isProduction } from '@/helpers/env'
import { patchProfile } from '@/services/profiles'
import { showToast } from '@/utils/toast/index'

const signup = (userData, callback) => async (dispatch) => {
  try {
    dispatch(SET_USERS_LOADING(true))

    const user = await postRegisterUser(userData)

    if (user) {
      dispatch(USER_PROFILE_UPDATED(user))
    }

    if (isProduction) {
      // [AdWords] Track Signup conversion
      window.gtag('event', 'conversion', { send_to: 'AW-11362644115/kSlTCIyjo_MYEJPhkKoq' })
    }

    if (callback) {
      // to avoid redirect because of authenticated user
      setTimeout(() => callback())
    }
  } catch (error) {
    dispatch(USER_AUTHENTICATION_UPDATED(false))
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_USERS_LOADING(false))
  }
}

const authWithGoogle = (data, callback) => async (dispatch) => {
  try {
    dispatch(SET_USERS_LOADING(true))

    const user = await postAuthUserWithGoogle(data)

    if (data?.action === 'signup' && user?.responseStatus === 201 && isProduction) {
      // [AdWords] Track Signup conversion
      window.gtag('event', 'conversion', { send_to: 'AW-11362644115/kSlTCIyjo_MYEJPhkKoq' })
    }

    await dispatch(
      tryAutoLogin(
        () => showToast('Welcome!'),
        () => showToast('User could not be verified', 'error'),
      ),
    )

    if (callback) {
      callback()
    }
  } catch (error) {
    dispatch(USER_AUTHENTICATION_UPDATED(false))
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_USERS_LOADING(false))
  }
}

const login = (userCredentials, callback) => async (dispatch) => {
  try {
    dispatch(SET_USERS_LOADING(true))

    await postAuthenticateUser(userCredentials)
    await dispatch(
      tryAutoLogin(
        () => showToast('Welcome!'),
        () => showToast('User could not be verified', 'error'),
      ),
    )

    if (callback) {
      callback()
    }
  } catch (error) {
    dispatch(USER_AUTHENTICATION_UPDATED(false))
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_USERS_LOADING(false))
  }
}

const tryAutoLogin = (onSuccess, onError) => async (dispatch) => {
  try {
    dispatch(SET_USERS_LOADING(true))

    const user = await fetchUserMe()

    if (user) {
      dispatch(USER_PROFILE_UPDATED(user))
    } else {
      dispatch(USER_AUTHENTICATION_UPDATED(false))
    }

    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    if (onError) {
      onError()
    }
    dispatch(USER_AUTHENTICATION_UPDATED(false))
  } finally {
    dispatch(SET_USERS_LOADING(false))
  }
}

const logout = (callback) => async (dispatch) => {
  try {
    dispatch(SET_USERS_LOADING(true))

    await postLogoutUser()
    dispatch(USER_PROFILE_UPDATED(null))

    showToast('Come back soon!')

    if (callback) {
      callback()
    }
  } catch (error) {
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_USERS_LOADING(false))
  }
}

const updateProfile = (username, data) => async (dispatch, getState) => {
  try {
    dispatch(SET_USERS_LOADING(true))

    const { userProfile } = getState().users

    await patchProfile(username, data)

    dispatch(USER_PROFILE_UPDATED({ ...userProfile, ...data }))
  } catch (error) {
    const { message } = error
    showToast(message, 'error')
  } finally {
    dispatch(SET_USERS_LOADING(false))
  }
}

const getUserStats = () => async (dispatch) => {
  try {
    dispatch(SET_USER_STATS_LOADING(true))

    const stats = await fetchUserStats()

    dispatch(USER_STATS_UPDATED(stats))
  } catch (error) {
    const { message } = error
    dispatch(SET_USERS_ERROR(message))
  }
}

const forgotPassword = (data) => async (dispatch) => {
  try {
    dispatch(SET_USERS_LOADING(true))

    await postForgotPassword(data)
    showToast('An email has been sent with instructions on how to reset your password', 'success')
  } catch (error) {
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_USERS_LOADING(false))
  }
}

const resetPassword = (data) => async (dispatch) => {
  try {
    dispatch(SET_USERS_LOADING(true))

    const res = await postResetPassword(data)
    showToast('New password set successfully', 'success')
    return res
  } catch (error) {
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_USERS_LOADING(false))
  }
}

const validateEmail = (isResend, userId) => async (dispatch, getState) => {
  dispatch(CLEAR_ERRORS())

  try {
    dispatch(SET_USERS_LOADING(true))

    const { userProfile } = getState().users
    const id = userProfile?.id

    await postValidateUserEmail(id || userId)

    dispatch(
      USER_EMAIL_VALIDATION_UPDATED({
        [isResend ? 'emailValidationCodeIsResent' : 'emailValidationCodeIsSent']: true,
        emailValidationCodeError: null,
      }),
    )

    showToast('Verification code sent!')
  } catch (error) {
    showToast(error.message, 'error')
    dispatch(
      USER_EMAIL_VALIDATION_UPDATED({
        [isResend ? 'emailValidationCodeIsResent' : 'emailValidationCodeIsSent']: false,
        emailValidationCodeError: error.message,
      }),
    )
  } finally {
    dispatch(SET_USERS_LOADING(false))
  }
}

const confirmEmail = (token, userId) => async (dispatch, getState) => {
  dispatch(CLEAR_ERRORS())

  try {
    dispatch(SET_USERS_LOADING(true))

    const { userProfile } = getState().users
    const id = userProfile?.id

    await postConfirmUserEmail(id || userId, token)

    dispatch(
      USER_EMAIL_VALIDATION_UPDATED({
        emailValidationCodeIsValidated: true,
        emailValidationCodeError: null,
      }),
    )
  } catch (error) {
    dispatch(
      USER_EMAIL_VALIDATION_UPDATED({
        emailValidationCodeIsValidated: false,
        emailValidationCodeError: error.message,
      }),
    )
  } finally {
    dispatch(SET_USERS_LOADING(false))
  }
}

const createCheckout = (billingPeriod, pathname) => async (dispatch) => {
  try {
    dispatch(SET_USERS_LOADING(true))

    const response = await postUserCheckout({
      interval: billingPeriod,
      redirect_url: pathname,
    })

    return response?.checkout_url
  } catch (error) {
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_USERS_LOADING(false))
  }
}

const createBilling = (cb) => async (dispatch) => {
  try {
    dispatch(SET_USERS_LOADING(true))

    const response = await postUserBilling()

    dispatch(USER_BILLING_URL_UPDATED(response?.billing_url))

    if (cb) {
      cb(response?.billing_url)
    }
  } catch (error) {
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_USERS_LOADING(false))
  }
}

const createUserFeedback = (data, cb) => async (dispatch) => {
  try {
    dispatch(SET_USERS_LOADING(true))

    const response = await postUserFeedback(data)

    if (cb) {
      cb(response)
    }

    return response
  } catch (error) {
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_USERS_LOADING(false))
  }
}

const toggleAuthModal = (isOpen) => (dispatch) => {
  dispatch(TOGGLE_AUTH_MODAL(isOpen))
}

const togglePublicProfileModal = (isOpen) => (dispatch) => {
  dispatch(TOGGLE_PUBLIC_PROFILE_MODAL(isOpen))
}

const toggleEmailVerificationModal = (isOpen) => (dispatch) => {
  dispatch(TOGGLE_EMAIL_VERIFICATION_MODAL(isOpen))
}

export {
  signup,
  authWithGoogle,
  login,
  tryAutoLogin,
  logout,
  forgotPassword,
  resetPassword,
  updateProfile,
  getUserStats,
  validateEmail,
  confirmEmail,
  createCheckout,
  createBilling,
  createUserFeedback,
  toggleAuthModal,
  togglePublicProfileModal,
  toggleEmailVerificationModal,
}
