import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.base.gray1};
  border-top: 1px solid ${(props) => props.theme.colors.base.gray4};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  padding: 12px;
`

export const PagesContainer = styled.div`
  width: auto;
  max-width: 300px;
  background-color: ${(props) => props.theme.colors.base.gray2};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 4px;
  overflow: hidden;
  padding: 12px 14px;
  transition:
    max-width 0.8s,
    background-color 0.3s;

  .text {
    display: none;
  }

  ${(props) =>
    props.isCollapsed &&
    css`
      max-width: 50px;
      cursor: pointer;

      &:hover {
        background-color: ${(props) => props.theme.colors.base.gray3};
      }

      > .page {
        display: none;
      }

      .text {
        color: ${(props) => props.theme.colors.base.gray8};
        display: block;
        text-align: center;
        font-size: 11px;
        line-height: 22px;
        letter-spacing: 1.76px;
        text-transform: uppercase;
      }

      ${!props.hasContent &&
      css`
        cursor: not-allowed;

        &:hover {
          background-color: ${(props) => props.theme.colors.base.gray2};
        }
      `}

      ${props.isCompleted &&
      css`
        &:hover {
          background-color: ${(props) => props.theme.colors.base.geekblue1};
        }

        .text {
          color: ${(props) => props.theme.colors.base.geekblue5};
        }
      `}
    `};

  ${(props) =>
    props.isCompleted &&
    css`
      background-color: ${(props) => props.theme.colors.base.geekblue1};
    `}
`

export const Page = styled.div`
  min-width: 24px;
  height: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;

  > .content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 3px solid ${(props) => props.theme.colors.base.gray5};
    padding: 2px;
    transition: all 0.3s;

    ${(props) =>
      props.hasContent
        ? css`
            &:hover {
              border: 2px solid ${(props) => props.theme.colors.base.geekblue4};

              .value {
                color: ${(props) => props.theme.colors.base.gray1};
                background-color: ${(props) => props.theme.colors.base.geekblue4};
              }
            }
          `
        : css`
            cursor: not-allowed;
          `}
  }

  &:after {
    width: 10px;
    content: '';
    border-bottom: 1px solid ${(props) => props.theme.colors.base.gray10}16;
    position: absolute;
    right: -15px;
  }

  &:last-of-type {
    &:after {
      display: none;
    }
  }

  .value {
    width: 100%;
    height: 100%;
    color: ${(props) => props.theme.colors.base.gray7};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
  }

  ${(props) =>
    props.isVisited &&
    css`
      &:hover > .content,
      > .content {
        border-color: ${(props) => props.theme.colors.base.geekblue5};

        .value {
          color: ${(props) => props.theme.colors.base.gray10};
        }
      }
    `}

  ${(props) =>
    props.isActive &&
    css`
      &:hover > .content,
      > .content {
        border: 2px solid ${(props) => props.theme.colors.base.geekblue5};

        .value {
          color: ${(props) => props.theme.colors.base.gray1};
          background-color: ${(props) => props.theme.colors.base.geekblue5};
        }
      }
    `}
`
